<template>
    <div>
        <div v-show="show" class="nav" v-if="obj.status != 1">
            <div class="display padding">
                <div class="title">通知将于{{ obj.regular_time
                }} 发出</div>
                <div class="btn" @click="getEdit">修改</div>
                <van-icon @click="show = false" class="icon" name="cross" />
            </div>
        </div>
        <div class="box">
            <div class="box_title"> {{ obj.title }}</div>
            <div class="display">
                <div class="name">{{ obj.create_er }}</div>
                <div class="time">{{ obj.create_date }}</div>
            </div>
        </div>
        <div class="linner"></div>
        <!-- 内容 -->
        <div class="">
            <div class="contain padding">
                {{ obj.content }}
            </div>
            <div class="image padding">
                <div class="img" v-for="(item, index) in obj.images" :key="index">
                    <img :src="item" alt="" @click="previewImage(index)">
                </div>
            </div>
        </div>
        <div class="" @click="getRead">
            <div class="padding displays">
                <div class="read_num">接收人：已读{{ obj.read_num }}</div>
                <div class="unread_num">未读{{ obj.unread_num }}<van-icon name="arrow" /> </div>
            </div>
        </div>
        <!-- 返回 -->
        <div class="bottom">
            <div class="bottom_box">
                <van-icon @click="getUp" class="" name="arrow-left" />
                <van-icon class="icon" name="arrow" />
            </div>
        </div>

    </div>
</template>
<script>
import { ImagePreview } from 'vant'
import { NoticeDetail } from "../../api/notice/notice";
import Cookies from "js-cookie";

export default {
    data() {
        return {
            show: false,
            imgList: [],
            obj: {
            },
            oss_url: process.env.VUE_APP_OSS_URL,
        }
    },
    created() {
      this.id = this.$route.query.id
      this.getDetail()
    },
    methods: {
        getUp() {
            this.$router.push("/noticeteach");
        },
        // 图片预览
        previewImage(index) {
            console.log(index);
            console.log(this.imgList);
            ImagePreview({
                images: this.imgList,
                startPosition: index
            });
        },
        // 修改
        getEdit() {
            let str = JSON.stringify(this.obj);
            this.$router.push({ path: "/publish", query: { str } });

        },
        // 详情
        getDetail() {
            NoticeDetail({ id: this.id }).then((res) => {
                this.obj = res.data
                if (this.obj.is_regular == 1) {
                    this.show = true
                } else {
                    this.show = false
                }
                
                this.obj.images.forEach(item => {
                    var url = item
                    this.imgList.push(url)
                });

                // this.imgList 
            })
        },
        // 
        getRead() {
            let str = JSON.stringify(this.obj);
            this.$router.push({ path: "/read", query: { str } });

        }
    }
}

</script>
<style lang="less" scoped>
.display {
    display: flex;
    align-items: center;
}

.displays {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.padding {
    padding: 0 15px;

}

.nav {
    width: 100%;
    height: 40px;
    background: #F1F4FF;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    align-items: center;
}

.title {
    width: 202px;
    height: 20px;
    font-size: 13px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #366FF7;
}

.btn {
    width: 52px;
    height: 24px;
    background: #366FF7;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    color: #ffffff;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
    margin-left: 59px;
}

.icon {
    color: #999999;
    margin-left: 20px;
}

.box {
    padding: 0 15px;
    margin-top: 15px;

    .box_title {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        padding-bottom: 6px;
    }

    .btns {
        width: 52px;
        height: 18px;
        background: #E5EDFA;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        font-size: 10px;
        color: #5380FF;
    }
    .name {
        width: 15vw;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    .time {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-left: 15px;
    }
}

.linner {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-top: 16px;
}

.contain {
    width: 343px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-top: 16px;
    line-height: 22px;
}

.image {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .img {
        width: 105px;
        height: 105px;
        opacity: 1;
        background-color: #366FF7;
        border-radius: 8px;
        margin-right: 10px;
        margin-top: 10px;

        img {
            width: 105px;
            height: 105px;
            border-radius: 8px;
        }
    }
}

.bottom {
    position: fixed;
    bottom: 0;
    height: 49px;
    background: #F7F7F7;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .bottom_box {
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.read_num {
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
}

.unread_num {
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
}
</style>